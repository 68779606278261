import { collection, addDoc, query, where, getDocs, orderBy, doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { db, functions } from '@/firebase/config.js';
import { httpsCallable } from "firebase/functions";

import moment from "moment";

import store from '@/store';

export const getSoftBlocks = async() => {

    let softBlocks = [];
    const q = query(collection(db, 'caap_blocks'), orderBy("userEmail", "asc"));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        softBlocks.push({ id: doc.id, data: doc.data() })
    });

    return softBlocks;
}


export const getPostExamSurveyInfo = async(docId) => {

    let postExamSurveyInfo;

    const docRef = doc(db, "post_exam_survey", docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        postExamSurveyInfo = docSnap.data();
    }

    return postExamSurveyInfo;
}

export const getAchievementBoxInfo = async(docId) => {

    let achievementBoxInfo;

    const docRef = doc(db, "achievement_box", docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        achievementBoxInfo = docSnap.data();
    }

    return achievementBoxInfo;
}

export const addSoftBlock = async(user) => {

    // validate
    if (!user.email || !user.uid) {
        return { error: true, message: 'User not found!' }
    }

    // check if email already exist
    let isFound = false;
    const q = query(collection(db, 'caap_blocks'), where("userEmail", "==", user.email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        isFound = true;
    });
    if (isFound) {
        return { error: true, message: 'User has been added previously!' }
    }

    // get email of the admin user
    let curAuthUser = store.state.user.otherInfo.email;

    let data = {
        userId: user.uid,
        userEmail: user.email,
        status: 'blocked',
        postExamSurveyStatus: 'waiting',
        achievementBoxStatus: 'waiting',
        metadata: {
            blockedOn: moment().valueOf().toString(),
            blockedBy: curAuthUser,
            updatedOn: moment().valueOf().toString(),
            updatedBy: curAuthUser,
            rejectedCounter: 0
        },
    }

    try {
        // add to caap_blocks collection
        const res = await addDoc(collection(db, "caap_blocks"), data);

        // update user claims
        const softBlockUserBlockFunc = httpsCallable(functions, "softBlockUserBlock");
        const softBlockUserBlockFuncRes = await softBlockUserBlockFunc({ email: user.email })

        if (softBlockUserBlockFuncRes.data.success) {
            return { error: false, data: res }
        } else {
            return { error: true, message: 'There was an error while saving the data...' }
        }

    } catch (error) {
        return { error: true, message: 'API call error...' }
    }

}


export const approveSoftBlock = async(softBlock) => {

    // check if email already exist
    let isFound = false;
    const q = query(collection(db, 'caap_blocks'), where("userEmail", "==", softBlock.data.userEmail));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        isFound = true;
    });
    if (!isFound) {
        return { error: true, message: 'User does not exist!' }
    }

    // get email of the admin user
    let curAuthUser = store.state.user.otherInfo.email;

    try {

        await Promise.all([

            // update caap_blocks collection for this user
            updateDoc(doc(db, "caap_blocks", softBlock.id), {
                status: 'unblocked',
                postExamSurveyStatus: 'approved',
                achievementBoxStatus: 'approved',
                'metadata.updatedOn': moment().valueOf().toString(),
                'metadata.updatedBy': curAuthUser
            }),

            // update achievement_box collection if it was not approved previously
            softBlock.data.achievementBoxStatus == 'submitted' &&
            updateDoc(doc(db, "achievement_box", softBlock.data.userId), {
                status: 'approved',
                'metadata.approvedBy': curAuthUser,
                'metadata.approvedOn': moment().valueOf().toString(),
                'metadata.updatedOn': moment().valueOf().toString()
            }),

            // update post_exam_survey collection if it was not approved previously
            softBlock.data.postExamSurveyStatus == 'submitted' &&
            updateDoc(doc(db, "post_exam_survey", softBlock.data.userId), {
                status: 'approved',
                'metadata.approvedBy': curAuthUser,
                'metadata.approvedOn': moment().valueOf().toString(),
                'metadata.updatedOn': moment().valueOf().toString()
            }),
            // Set caap exam data value from 'active' to 'expired'
            updateDoc(doc(db, "users", softBlock.data.userId), {
                'reviewers.caap': 'expired'
            })
        ]);

        // update user claims
        const softBlockUserUnblockFunc = httpsCallable(functions, "softBlockUserUnblock");
        const softBlockUserUnblockFuncRes = await softBlockUserUnblockFunc({ email: softBlock.data.userEmail })

        if (softBlockUserUnblockFuncRes.data.success) {
            return { error: false, data: { message: 'success!' } }
        } else {
            return { error: true, message: 'There was an error while unblocking user...' }
        }

    } catch (error) {
        return { error: true, message: 'API call error...' }
    }

}

export const rejectSoftBlock = async(softBlock) => {

    console.log(softBlock);

    // console.log(softBlock.newPostExamSurveyStatus ? softBlock.newPostExamSurveyStatus : softBlock.data.postExamSurveyStatus);
    // console.log(softBlock.newAchievementBoxStatus ? softBlock.newAchievementBoxStatus : softBlock.data.achievementBoxStatus);

    const postExamSurveyStat = softBlock.newPostExamSurveyStatus ? softBlock.newPostExamSurveyStatus : softBlock.data.postExamSurveyStatus;
    const achievementBoxStat = softBlock.newAchievementBoxStatus ? softBlock.newAchievementBoxStatus : softBlock.data.achievementBoxStatus;

    // check if email already exist
    let isFound = false;
    const q = query(collection(db, 'caap_blocks'), where("userEmail", "==", softBlock.data.userEmail));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        isFound = true;
    });
    if (!isFound) {
        return { error: true, message: 'User does not exist!' }
    }

    // get email of the admin user
    let curAuthUser = store.state.user.otherInfo.email;

    try {

        await Promise.all([

            // update caap_blocks collection for this user
            updateDoc(doc(db, "caap_blocks", softBlock.id), {
                status: 'blocked',
                postExamSurveyStatus: postExamSurveyStat,
                achievementBoxStatus: achievementBoxStat,
                'metadata.updatedOn': moment().valueOf().toString(),
                'metadata.updatedBy': curAuthUser,
                'metadata.rejectedCounter': softBlock.data.metadata.rejectedCounter + 1
            }),

            // update achievement_box collection
            updateDoc(doc(db, "achievement_box", softBlock.data.userId), {
                status: achievementBoxStat,
                'metadata.approvedBy': achievementBoxStat == 'approved' ? curAuthUser : null,
                'metadata.approvedOn': achievementBoxStat == 'approved' ? moment().valueOf().toString() : null,
                'metadata.updatedOn': moment().valueOf().toString()
            }),

            // update post_exam_survey collection
            updateDoc(doc(db, "post_exam_survey", softBlock.data.userId), {
                status: postExamSurveyStat,
                'metadata.approvedBy': postExamSurveyStat == 'approved' ? curAuthUser : null,
                'metadata.approvedOn': postExamSurveyStat == 'approved' ? moment().valueOf().toString() : null,
                'metadata.updatedOn': moment().valueOf().toString()
            }),
        ]);


        return { error: false, data: { message: 'success!' } }

        // // update user claims
        // const softBlockUserUnblockFunc = httpsCallable(functions, "softBlockUserUnblock");
        // const softBlockUserUnblockFuncRes = await softBlockUserUnblockFunc({ email: softBlock.data.userEmail })

        // if (softBlockUserUnblockFuncRes.data.success) {
        //     return { error: false, data: { message: 'success!' } }
        // } else {
        //     return { error: true, message: 'There was an error while unblocking user...' }
        // }

    } catch (error) {
        return { error: true, message: 'API call error...' }
    }

}

export const removeSoftBlock = async(softBlock) => {

    const getReviewerExpiry = (days = 90) => {
        let durationInDays = days;
        const currentDate = new Date()
        currentDate.setDate(currentDate.getDate() + durationInDays)
        // const expiryTimestamp = Timestamp.fromDate(currentDate)
        return currentDate
    }


    const expDate = getReviewerExpiry();
    // const expDate = new Date('2024-07-01');
    // const expDate = new Date('2024-07-01T01:00:00');

    await Promise.all([
        // remove from caap_blocks collection
        deleteDoc(doc(db, "caap_blocks", softBlock.id)),

        // NOW, EXTENDING USERS WILL NO HAVE EFFECT TO ASSESSMENT PAGE!!!
        // remove from achievement_box collection
        // deleteDoc(doc(db, "achievement_box", softBlock.data.userId)),
        // remove from post_exam_survey collection
        // deleteDoc(doc(db, "post_exam_survey", softBlock.data.userId)),

        // Set caap exam data value from 'active' to 'expired'
        updateDoc(doc(db, "users", softBlock.data.userId), {
            'reviewers.caap': 'active',
            'isCaapBlocked': false,
            'caap_data.ext_counter': 1, 
            'caap_data.expiration_date': expDate
            // add caap_data here...
        })
    ]);

    // update user claims
    const softBlockUserRemoveFunc = httpsCallable(functions, "softBlockUserRemove");
    const softBlockUserRemoveFuncRes = await softBlockUserRemoveFunc({ email: softBlock.data.userEmail, caapExpAt: expDate })

    if (softBlockUserRemoveFuncRes.data.success) {
        return { error: false, data: { message: 'success!' } }
    } else {
        return { error: true, message: 'There was an error while removing user to soft block...' }
    }

}

export const updateShipping = async(softBlock, data) => {

    try {

        await Promise.all([

            // update caap_blocks collection for this user
            updateDoc(doc(db, "caap_blocks", softBlock.id), {
                achievementBoxStatus: 'shipped',
            }),
            // update achievement_box collection
            updateDoc(doc(db, "achievement_box", softBlock.data.userId), {
                status: 'shipped',
                courierName: data.courierName,
                trackingNum: data.trackingNum,
                trackingUrl: data.trackingUrl,
                'metadata.shippedBy': data.shippedBy,
                'metadata.shippedOn': moment(data.shippedOn).valueOf().toString(),
            }),
        ]);

        return { error: false, data: { message: 'success!' } }

    } catch (error) {
        return { error: true, message: 'API call error...' }
    }

}