<template>
  <div class="flex-row border-b dark:border-warm-gray-700">
    <div class="flex justify-between items-center mb-8">
      <h2 class="text-xl font-extrabold text-warm-gray-700 tracking-wider dark:text-warm-gray-100">CAAP Subscription</h2>
    </div>
    <div class="mb-2 flex justify-start gap-2">
      <button @click="tabView=1" :class="[' px-4 py-1 border rounded-md hover:border-yellow-600', tabView==1?'border-yellow-600':'border-warm-gray-700']">
        Blocked <span class=" rounded-full px-2 bg-warm-gray-500 text-warm-gray-900 text-xs">{{ blockedUsers.length }}</span>
      </button>
      <button @click="tabView=2" :class="[' px-4 py-1 border rounded-md hover:border-yellow-600', tabView==2?'border-yellow-600':'border-warm-gray-700']">
        Appealed <span class=" rounded-full px-2 bg-warm-gray-500 text-warm-gray-900 text-xs">{{ appealedUsers.length }}</span>
      </button>
      <button @click="tabView=3" :class="[' px-4 py-1 border rounded-md hover:border-yellow-600', tabView==3?'border-yellow-600':'border-warm-gray-700']">
        Unblocked <span class=" rounded-full px-2 bg-warm-gray-500 text-warm-gray-900 text-xs">{{ unblockedUsers.length }}</span>
      </button>
    </div>
  </div>


  <div class=" flex justify-center mb-2 text-warm-gray-600 dark:text-warm-gray-200">
    
    <div class="w-full lg:w-5/6 mt-10">

      <div class="p-4 flex justify-between items-center border-b border-warm-gray-600">
        <h2 v-if="tabView == 1" class=" text-xl font-semibold">Blocked users</h2>
        <h2 v-if="tabView == 2" class=" text-xl font-semibold">Appealed users</h2>
        <h2 v-if="tabView == 3" class=" text-xl font-semibold">Unblocked users</h2>
        <div class="flex items-center">
          <!-- <button class=" bg-yellow-600 text-white font-semibold p-2 rounded-md focus: outline-none hover:bg-yellow-700 focus:bg-yellow-700" @click="isAddModalOpen=true">
            Add user to block
          </button> -->
          <button type="button" @click="getList()" class="p-2 border border-warm-gray-400 rounded-md ml-2 hover:bg-warm-gray-300 dark:hover:bg-warm-gray-700">
            <ArrowPathIcon class="w-4 h-4" />
          </button>
          <!-- <button class=" bg-yellow-600 text-white font-semibold p-2 rounded-md focus: outline-none hover:bg-yellow-700 focus:bg-yellow-700" @click="sendSMS()">Send test SMS</button> -->
        </div>
      </div>

      <div v-if="tabView==1">
        <div v-for="(blockedUser,i) in blockedUsers" class="border border-warm-gray-600 p-4 rounded mt-3">
          <div class="my-2 mb-2 md:flex justify-between">
            <div>
              <a :href="'/v2/user/'+blockedUser.data.userId" target="_blank">
                <b><span class="text-yellow-600">{{ blockedUser.data.userEmail }}</span></b>
              </a>
              <div class=" italic text-warm-gray-500">
                Blocked date: {{ formatDate(blockedUser.data.metadata.blockedOn) }}
              </div>
              <div class=" italic text-warm-gray-500">
                Rejected: {{ blockedUser.data.metadata.rejectedCounter }}
              </div>
            </div>
            <button @click="remove(blockedUsers[i])" type="button" class="border rounded p-2 w-full md:w-48 border-warm-gray-500 text-warm-gray-500 flex justify-center items-center mt-2 md:mt-0">
              <CalendarDaysIcon class="w-5 h-4"/> <span>Extend 3 months</span>
            </button>
          </div>
  
        </div>
      </div>
      <div v-if="tabView==2">
        <div v-for="(appealedUser,i) in appealedUsers" class="border border-warm-gray-600 p-4 rounded mt-3">
          <div class="my-2 mb-4 md:flex justify-between">
            <a :href="'/v2/user/'+appealedUser.data.userId" target="_blank">
              <b><span class="text-yellow-600">{{ appealedUser.data.userEmail }}</span></b>
            </a>
            <div class=" italic text-warm-gray-500 hidden md:block">
              Rejected: {{ appealedUser.data.metadata.rejectedCounter }}
            </div>
          </div>
  
          <div class="border rounded border-warm-gray-400 bg-warm-gray-100 dark:border-warm-gray-600 dark:bg-warm-gray-800 lg:flex lg:justify-between my-2 p-2">
            <div class="mb-2 lg:mb-0 flex justify-between">
              <div>
                <span class=" text-warm-gray-800 dark:text-warm-gray-200">Post Exam Survery</span>
                <i class="text-gray-500 capitalize"> {{ appealedUser.data.postExamSurveyStatus == 'waiting' ? '' : ' - ' + appealedUser.data.postExamSurveyStatus }}</i>
                <button v-if="appealedUser.data.postExamSurveyStatus != 'waiting'" @click="showPostExamSurveyInfo(appealedUser.data.userId)" type="button" class=" text-purple-800 underline ml-2">View</button>
              </div>
  
              <div v-if="appealedUser.newPostExamSurveyStatus" @dblclick="delete appealedUsers[i].newPostExamSurveyStatus" class="md:hidden block">
                <HandThumbUpIcon v-if="appealedUser.newPostExamSurveyStatus == 'approved'" class="w-5 h-5 m-1 text-green-600"/>
                <HandThumbDownIcon v-else class="w-5 h-5 m-1 text-red-600"/>
              </div>
  
            </div>
            <div v-if="appealedUser.data.postExamSurveyStatus == 'submitted' && !appealedUser.newPostExamSurveyStatus" class="flex w-full md:w-min md:items-end">
              <button type="button" @click="appealedUsers[i].newPostExamSurveyStatus = 'approved'" class="border border-green-600 rounded text-green-700 px-2 py-1 w-1/2 flex items-center justify-center">
                <HandThumbUpIcon class="w-4 h-4"/>
              </button>
              <button type="button" @click="appealedUsers[i].newPostExamSurveyStatus = 'rejected'" class="border border-red-600 rounded text-red-700 px-2 py-1 ml-2 w-1/2 flex items-center justify-center">
                <HandThumbDownIcon class="w-4 h-4"/>
              </button>
            </div>
            <div v-if="appealedUser.newPostExamSurveyStatus" @dblclick="delete appealedUsers[i].newPostExamSurveyStatus" class="hidden md:block">
              <HandThumbUpIcon v-if="appealedUser.newPostExamSurveyStatus == 'approved'" class="w-5 h-5 m-1 text-green-600"/>
              <HandThumbDownIcon v-else class="w-5 h-5 m-1 text-red-600"/>
            </div>
          </div>
  
          <div class="border rounded border-warm-gray-400 bg-warm-gray-100 dark:border-warm-gray-600 dark:bg-warm-gray-800 lg:flex lg:justify-between my-2 p-2">
            <div class="mb-2 lg:mb-0 flex justify-between">
              <div>
                <span class=" text-warm-gray-800 dark:text-warm-gray-200">Achievement Box</span> <i class="text-gray-500 capitalize"> {{ appealedUser.data.achievementBoxStatus == 'waiting' ? '' : ' - ' + appealedUser.data.achievementBoxStatus }}</i>
                <button v-if="appealedUser.data.achievementBoxStatus != 'waiting'" @click="showAchievementBoxInfo(appealedUser.data.userId)" type="button" class=" text-purple-800 underline ml-2">View</button>              
              </div>
              <div v-if="appealedUser.newAchievementBoxStatus" @dblclick="delete appealedUsers[i].newAchievementBoxStatus" class="md:hidden block">
                <HandThumbUpIcon v-if="appealedUser.newAchievementBoxStatus == 'approved'" class="w-5 h-5 m-1 text-green-600"/>
                <HandThumbDownIcon v-else class="w-5 h-5 m-1 text-red-600"/>
              </div>
  
            </div>
            <div v-if="appealedUser.data.achievementBoxStatus == 'submitted' && !appealedUser.newAchievementBoxStatus" class="flex w-full md:w-min md:items-end">
              <button type="button" @click="appealedUsers[i].newAchievementBoxStatus = 'approved'" class="border border-green-600 rounded text-green-700 px-2 py-1 w-1/2 flex items-center justify-center">
                <HandThumbUpIcon class="w-4 h-4"/>
              </button>
              <button type="button" @click="appealedUsers[i].newAchievementBoxStatus = 'rejected'" class="border border-red-600 rounded text-red-700 px-2 py-1 ml-2 w-1/2 flex items-center justify-center">
                <HandThumbDownIcon class="w-4 h-4"/>
              </button>
            </div>
            <div v-if="appealedUser.newAchievementBoxStatus" @dblclick="delete appealedUsers[i].newAchievementBoxStatus" class="hidden md:block">
              <HandThumbUpIcon v-if="appealedUser.newAchievementBoxStatus == 'approved'" class="w-5 h-5 m-1 text-green-600"/>
              <HandThumbDownIcon v-else class="w-5 h-5 m-1 text-red-600"/>
            </div>
          </div>
  
          <div class="border-t border-warm-gray-700 pt-2 mt-4 lg:flex lg:justify-between">
            <div class="flex justify-evenly mb-2 lg:mb-0">
              <button @click="approveAppeal(appealedUsers[i])" type="button" class="border rounded p-2 w-full lg:w-32 border-green-700 text-green-700 disabled:opacity-30 disabled:cursor-not-allowed" :disabled="appealedUser.data.status != 'appealed'">Approve Appeal</button>
              <button @click="rejectAppeal(appealedUsers[i])" type="button" class="border rounded p-2 w-full lg:w-32 ml-2 border-red-700 text-red-700 disabled:opacity-30 disabled:cursor-not-allowed" :disabled="appealedUser.data.status != 'appealed'">Reject Appeal</button>
            </div>
            <button @click="remove(appealedUsers[i])" type="button" class="border rounded p-2 w-full lg:w-48 border-warm-gray-500 text-warm-gray-500 flex justify-center items-center">
              <TrashIcon class="w-5 h-4"/> <span>Extend 3 months</span>
            </button>
          </div>
  
        </div>
      </div>
      <div v-if="tabView==3">
        <div v-for="(unblockedUser,i) in unblockedUsers" class="border border-warm-gray-600 p-4 rounded mt-3">
          <div class="my-2 mb-4 md:flex justify-between">
            <a :href="'/v2/user/'+unblockedUser.data.userId" target="_blank">
              <b><span class="text-yellow-600">{{ unblockedUser.data.userEmail }}</span></b>
            </a>
            <div class=" italic text-warm-gray-500 hidden md:block">
              Rejected: {{ unblockedUser.data.metadata.rejectedCounter }}
            </div>
          </div>
  
          <div class="border rounded border-warm-gray-400 bg-warm-gray-100 dark:border-warm-gray-600 dark:bg-warm-gray-800 lg:flex lg:justify-between my-2 p-2">
            <div class="mb-2 lg:mb-0 flex justify-between">
              <div>
                <span class=" text-warm-gray-800 dark:text-warm-gray-200">Post Exam Survery</span>
                <i class="text-gray-500 capitalize"> {{ unblockedUser.data.postExamSurveyStatus == 'waiting' ? '' : ' - ' + unblockedUser.data.postExamSurveyStatus }}</i>
                <button v-if="unblockedUser.data.postExamSurveyStatus != 'waiting'" @click="showPostExamSurveyInfo(unblockedUser.data.userId)" type="button" class=" text-purple-800 underline ml-2">View</button>
              </div>
            </div>
          </div>
  
          <div class="border rounded border-warm-gray-400 bg-warm-gray-100 dark:border-warm-gray-600 dark:bg-warm-gray-800 my-2 p-2">
            <div class="mb-2 lg:mb-0 flex justify-between">
              <div>
                <span class=" text-warm-gray-800 dark:text-warm-gray-200">Achievement Box</span> <i class="text-gray-500 capitalize"> {{ unblockedUser.data.achievementBoxStatus == 'waiting' ? '' : ' - ' + unblockedUser.data.achievementBoxStatus }}</i>
                <button @click="showAchievementBoxInfo(unblockedUser.data.userId)" type="button" class=" text-purple-800 underline ml-2">View</button>              
              </div>  
              <div>
                <span v-if="unblockedUser.data.achievementBoxStatus == 'shipped'">🚚💨</span>
                <span v-if="unblockedUser.data.achievementBoxStatus == 'received'">📦</span>
              </div>
            </div>
          </div>
  
          <div class="border-t border-warm-gray-700 pt-2 mt-4 lg:flex lg:justify-end">
  
            <div v-if="unblockedUser.data.status == 'unblocked'" @click="openShippingInfoModal(i)" class="mb-2 lg:mb-0">
              <button
                type="button" 
                class="border rounded p-2 w-full lg:w-52 border-green-700 text-green-700 disabled:opacity-30 disabled:cursor-not-allowed">Update Shipping Details</button>
            </div>
          </div>
  
        </div>
      </div>


    </div>
    

  <!-- Add Modal -->
  <TransitionRoot appear :show="isAddModalOpen" as="template">
    <Dialog as="div" @close="closeAddModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">

          <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40 dark:bg-warm-gray-400" />

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
            >
            <div
              class="inline-block w-full max-w-2xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-800"
            >
              <DialogTitle
                as="h3"
                class="flex items-center text-lg font-medium leading-6 text-white bg-yellow-600 p-6 pb-2"
               >
               Add user to block
              </DialogTitle>
              <div class="mt-2 flex-row items-start text-warm-gray-700 p-6 dark:text-warm-gray-200">

                <div class="w-full">
                  <label for="email">Email:</label>
                  <input type="email" id="email" v-model="addForm.email" class=" mb-2 w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2 dark:text-warm-gray-800" placeholder="Enter email address" />
                  <p  v-if="isGettingUid" class="text-yellow-600 flex justify-start items-center">
                    <SpinningIcon class="h-4 w-4 mr-1" />
                    <small>Checking for email... </small>
                  </p>
                  <p v-if="!isGettingUid && addForm.uid" class=" text-sm dark:text-yellow-600 text-yellow-700">User found!</p>
                </div>

              </div>

              <div class="mt-4 border-t p-6 pt-3 flex justify-between dark:border-warm-gray-600">
                <div>
                  <!-- <router-link :to="`/v2/user/${user.id}`" class="focus:outline-none"> -->
                  <router-link type="button" :to="`/v2/user/${addForm.uid}`" target="_blank" v-if="addForm.uid" class="inline-flex justify-center px-4 py-2 text-sm font-medium border rounded-md border-warm-gray-500 text-warm-gray-500 hover:underline hover:border-warm-gray-400">
                    View user details
                  </router-link>
                </div>
                <div>
                  <button
                    type="button"
                    class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600 dark:text-warm-gray-200"
                    @click="closeAddModal"
                    >
                    Cancel
                  </button>

                  <button
                    type="button"
                    :class=" [ !addForm.uid || isAdding ? 'disabled:cursor-not-allowed text-warm-gray-300 bg-red-300' : 'text-warm-gray-100 bg-red-600 hover:bg-red-800 focus:bg-red-800 dark:bg-red-700 dark:hover:bg-red-600 dark:focus:bg-red-600 dark:text-warm-gray-200' , 'ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500']"
                    @click="block"
                    :disabled="!addForm.uid || isAdding"
                    >
                    <SpinningIcon class="h-4 w-4 mr-1" v-if="isAdding" />
                    Block
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Post Exam Survey View Modal -->
  <TransitionRoot appear :show="isPostExamSurveyViewModalOpen" as="template">
    <Dialog as="div" @close="closePostExamSurveyViewModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">

          <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40 dark:bg-warm-gray-400" />

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
            >
            <div
              class="inline-block w-full max-w-4xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-800"
            >

              <div class="flex-row items-start p-6">

                <div class="border-b border-warm-gray-600 p-2">
                  <h3 class="text-warm-gray-900  dark:text-warm-gray-100 font-bold text-base">Post Exam Survey View</h3>
                </div>

                <div v-if="postExamSurveyInfo" class="mt-4 w-full">
                    <div class=" bg-yellow-200 p-2 text-yellow-600 font-semibold border-l-4 border-yellow-700">
                      <span class=" capitalize">{{ postExamSurveyInfo.status }}</span>
                    </div>

                    <div class="text-warm-gray-200 mt-4">

                      <div class="lg:flex flex-row mt-2">
                        <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Uploaded result URL:</div>
                        <div class=" w-full">
                          <a :href="postExamSurveyInfo.resultsUrl" target="_blank" class=" text-blue-400 hover:underline focus:outline-none focus:underline">{{ postExamSurveyInfo.resultsUrl }}</a>
                        </div>
                      </div>

                      <div class="lg:flex flex-row mt-2">
                        <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Inspiring Comment:</div>
                        <div class=" w-full text-warm-gray-400"> {{ postExamSurveyInfo.inspiringComment }}</div>
                      </div>
                      <div class="lg:flex flex-row mt-2">
                        <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Platform Feedback:</div>
                        <div class=" w-full text-warm-gray-400">{{ postExamSurveyInfo.platformFeedback }}</div>
                      </div>

                      <div class="border-t border-warm-gray-700 py-2 mt-4">
                        <h4 class="font-bold text-warm-gray-900 dark:text-warm-gray-50">Other Details</h4>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Submitted On:</div>
                          <div class=" w-full text-warm-gray-400">{{ formatDate(postExamSurveyInfo.metadata.submittedOn) }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Updated On:</div>
                          <div class=" w-full text-warm-gray-400">{{ formatDate(postExamSurveyInfo.metadata.updatedOn) }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Approved On:</div>
                          <div class=" w-full text-warm-gray-400">{{ formatDate(postExamSurveyInfo.metadata.approvedOn) }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Approved By:</div>
                          <div class=" w-full text-warm-gray-400">{{ postExamSurveyInfo.metadata.approvedBy ? postExamSurveyInfo.metadata.approvedBy : '-' }}</div>
                        </div>
                      </div>
                    </div>
                </div>

              </div>

              <div class="mt-4 border-t p-6 pt-3 flex justify-between dark:border-warm-gray-600">
                <div>

                </div>
                <div>
                  <button
                    type="button"
                    class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600 dark:text-warm-gray-200"
                    @click="closePostExamSurveyViewModal"
                    >
                    Close
                  </button>

                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>


  <!-- Achievement Box View Modal -->
  <TransitionRoot appear :show="isAchievementBoxViewModalOpen" as="template">
    <Dialog as="div" @close="closeAchievementBoxViewModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">

          <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40 dark:bg-warm-gray-400" />

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
            >
            <div
              class="inline-block w-full max-w-4xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-800"
            >

              <div class="flex-row items-start p-6">

                <div class="border-b border-warm-gray-600 p-2">
                  <h3 class="text-warm-gray-900  dark:text-warm-gray-100 font-bold text-base">Achievement Box View</h3>
                </div>

                <div v-if="achievementBoxInfo" class="mt-4 w-full">
                    <div class=" bg-yellow-200 p-2 text-yellow-600 font-semibold border-l-4 border-yellow-700">
                      <span class="capitalize">{{ achievementBoxInfo.status }}</span>
                    </div>

                    <div class="text-warm-gray-200 mt-4">

                      <div class="lg:flex flex-row">
                        <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">PEL Number:</div>
                        <div class=" w-full text-warm-gray-400">{{ achievementBoxInfo.pelNum }}</div>
                      </div>
                      
                      <div class="border-t border-warm-gray-700 py-2 mt-2">
                        <h4 class="font-bold text-warm-gray-900 dark:text-warm-gray-50">Shipping Details</h4>
                        <!-- <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Country:</div>
                          <div class=" w-full text-warm-gray-400">{{ achievementBoxInfo.shippingAddress.country }}</div>
                        </div> -->
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Province:</div>
                          <div class=" w-full text-warm-gray-400">{{ achievementBoxInfo.shippingAddress.province }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">City / Municipality:</div>
                          <div class=" w-full text-warm-gray-400">{{ achievementBoxInfo.shippingAddress.city }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-full md:w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Barangay:</div>
                          <div class=" w-full text-warm-gray-400">{{ achievementBoxInfo.shippingAddress.barangay }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-full md:w-1/2 text-warm-gray-900 dark:text-warm-gray-50">House No./Bldg./Street:</div>
                          <div class=" w-full text-warm-gray-400">{{ achievementBoxInfo.shippingAddress.addressLine1 }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">ZIP Code:</div>
                          <div class=" w-full text-warm-gray-400">{{ achievementBoxInfo.shippingAddress.zip ? achievementBoxInfo.shippingAddress.zip : '-' }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Complete Address:</div>
                          <div class=" w-full text-warm-gray-400">{{ achievementBoxInfo.shippingAddress.addressLine2 ? achievementBoxInfo.shippingAddress.addressLine2 : '-' }}</div>
                        </div>
                        
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Contact Number:</div>
                          <div class=" w-full text-warm-gray-400">{{ achievementBoxInfo.phone }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Tracking Number:</div>
                          <div class=" w-full text-warm-gray-400">{{ achievementBoxInfo.trackingNum ? achievementBoxInfo.trackingNum : '-' }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Tracking URL:</div>
                          <div class=" w-full text-warm-gray-400">{{ achievementBoxInfo.trackingUrl ? achievementBoxInfo.trackingUrl : '-' }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Courier Name:</div>
                          <div class=" w-full text-warm-gray-400">{{ achievementBoxInfo.courierName ? achievementBoxInfo.courierName : '-' }}</div>
                        </div>
                      </div>

                      <div class="border-t border-warm-gray-700 py-2 mt-2">
                        <h4 class="font-bold text-warm-gray-900 dark:text-warm-gray-50">Other Details</h4>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Submitted On:</div>
                          <div class=" w-full text-warm-gray-400">{{ formatDate(achievementBoxInfo.metadata.submittedOn) }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Updated On:</div>
                          <div class=" w-full text-warm-gray-400">{{ formatDate(achievementBoxInfo.metadata.updatedOn) }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Approved On:</div>
                          <div class=" w-full text-warm-gray-400">{{ formatDate(achievementBoxInfo.metadata.approvedOn) }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Approved By:</div>
                          <div class=" w-full text-warm-gray-400">{{ achievementBoxInfo.metadata.approvedBy ? achievementBoxInfo.metadata.approvedBy : '-' }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Shipped On:</div>
                          <div class=" w-full text-warm-gray-400">{{ formatDate(achievementBoxInfo.metadata.shippedOn, 'MMM. D, YYYY') }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Shipped By:</div>
                          <div class=" w-full text-warm-gray-400">{{ achievementBoxInfo.metadata.shippedBy ? achievementBoxInfo.metadata.shippedBy : '-' }}</div>
                        </div>
                        <div class="lg:flex flex-row mt-2">
                          <div class=" w-1/2 text-warm-gray-900 dark:text-warm-gray-50">Received On:</div>
                          <div class=" w-full text-warm-gray-400">{{ formatDate(achievementBoxInfo.metadata.receivedOn) }}</div>
                        </div>
                      </div>



                    </div>
                </div>

              </div>

              <div class="mt-4 border-t p-6 pt-3 flex justify-between dark:border-warm-gray-600">
                <div>

                </div>
                <div>
                  <button
                    type="button"
                    class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600 dark:text-warm-gray-200"
                    @click="closeAchievementBoxViewModal"
                    >
                    Close
                  </button>

                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

 <!-- Update Shipping Modal -->
 <TransitionRoot appear :show="isUpdateShippingModalOpen" as="template">
    <Dialog as="div" @close="closeUpdateShippingModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">

          <DialogOverlay class="fixed inset-0 bg-warm-gray-800 opacity-40 dark:bg-warm-gray-400" />

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
            >
            <div
              class="inline-block w-full max-w-2xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-warm-gray-800"
            >
              <DialogTitle
                as="h3"
                class="flex items-center text-lg font-medium leading-6 text-white bg-yellow-600 p-6 pb-2"
               >
               Update shipping details
              </DialogTitle>
              <div class="mt-2 flex-row items-start text-warm-gray-700 p-6 dark:text-warm-gray-200">
                <div class="w-full">
                  <label for="courier-name"> <span class=" text-red-600">*</span> Courier Name:</label>
                  <input id="courier-name" 
                    :disabled="isRetrievingAchievementBoxInfo"
                    v-model="shippingInfo.courierName"
                    class="disabled:cursor-wait disabled:opacity-25 mb-2 w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2 dark:text-warm-gray-800" placeholder="Enter courier name" />
                </div>
                <div class="w-full">
                  <label for="tracking-num"> <span class=" text-red-600">*</span> Tracking Number:</label>
                  <input id="tracking-num" 
                    :disabled="isRetrievingAchievementBoxInfo"
                    v-model="shippingInfo.trackingNum"
                    class="disabled:cursor-wait disabled:opacity-25 mb-2 w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2 dark:text-warm-gray-800" 
                    placeholder="Enter tracking number" />
                </div>
                <div class="w-full">
                  <label for="tracking-url">Tracking URL:</label>
                  <input id="tracking-url" 
                    :disabled="isRetrievingAchievementBoxInfo"
                    v-model="shippingInfo.trackingUrl"
                    class="disabled:cursor-wait disabled:opacity-25 mb-2 w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2 dark:text-warm-gray-800" 
                    placeholder="Enter tracking URL" />
                </div>
                <div class="w-full">
                  <label for="shipping-date"> <span class=" text-red-600">*</span> Shipping Date:</label>
                  <input type="date" id="shipping-date" 
                    :disabled="isRetrievingAchievementBoxInfo"
                    v-model="shippingInfo.shippedOn"
                    class="disabled:cursor-wait disabled:opacity-25 mb-2 w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2 dark:text-warm-gray-800" 
                    placeholder="Enter shipping date" />
                </div>
                <div class="w-full">
                  <label for="shipped-by"> <span class=" text-red-600">*</span> Shipped By:</label>
                  <input id="shipped-by" 
                    :disabled="isRetrievingAchievementBoxInfo"
                    v-model="shippingInfo.shippedBy"
                    class="disabled:cursor-wait disabled:opacity-25 mb-2 w-full h-10 p-2 rounded border bg-warm-gray-50 focus:outline-none focus:border-yellow-500 mt-2 dark:text-warm-gray-800" 
                    placeholder="Enter complete name" />
                </div>

              </div>

              <div class="mt-4 border-t p-6 pt-3 flex justify-between dark:border-warm-gray-600">
                <div>
                  <!-- <router-link :to="`/v2/user/${user.id}`" class="focus:outline-none"> -->
                  <span class=" text-yellow-600">
                    {{ isRetrievingAchievementBoxInfo ? 'Retrieving shipping info....' : '' }}
                  </span>
                </div>
                <div>
                  <button
                    type="button"
                    class="inline-flex justify-center px-4 py-2 text-sm font-medium text-warm-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 dark:bg-warm-gray-700 dark:hover:bg-warm-gray-600 dark:focus:bg-warm-gray-600 dark:text-warm-gray-200"
                    @click="closeUpdateShippingModal"
                    >
                    Cancel
                  </button>

                  <button
                    type="button"
                    class="disabled:cursor-not-allowed disabled:opacity-25 text-warm-gray-100 bg-red-600 hover:bg-red-800 focus:bg-red-800 dark:bg-red-700 dark:hover:bg-red-600 dark:focus:bg-red-600 dark:text-warm-gray-200 ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    @click="updateShippingInfo"
                    :disabled="isUpdatingShippingInfo || (shippingInfo.indexLoc != null && unblockedUsers[shippingInfo.indexLoc].data.achievementBoxStatus == 'received')"
                    >
                    <SpinningIcon class="h-4 w-4 mr-1" v-if="isUpdatingShippingInfo" />
                    Update
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <ProcessingModal v-if="isProcessing" :message="processingMessage"/>

  </div>

</template>


<script>

import moment from 'moment'
import {addSoftBlock, getSoftBlocks, getPostExamSurveyInfo, getAchievementBoxInfo, approveSoftBlock, rejectSoftBlock, removeSoftBlock, updateShipping } from '../firebase'
import ProcessingModal from '@/components/ProcessingModal';
import {
  // modal
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue';

import SpinningIcon from '@/components/SpinningIcon';

import swal from 'sweetalert2';

import {getUserOtherInfoFromEmail} from "@/firebase/controllers/userController";

import { HandThumbUpIcon, HandThumbDownIcon, TrashIcon, ArrowPathIcon, CalendarDaysIcon } from "@heroicons/vue/24/outline";

// Init a timeout variable to be used below
let timeout = null;

export default {
  data() {
    return {
      isProcessing: false,
      processingMessage: '',
      isAdding: false,
      isAddModalOpen: false,
      isPostExamSurveyViewModalOpen: false,
      isAchievementBoxViewModalOpen: false,
      isGettingUid: false,
      isUpdateShippingModalOpen: false,
      isUpdatingShippingInfo: false,
      isRetrievingAchievementBoxInfo: false,
      softBlocks: [],
      postExamSurveyInfo: null,
      achievementBoxInfo: null,
      addForm: {
        email: '',
        uid: ''
      },
      shippingInfo:{
        indexLoc: null,
        courierName: '',
        trackingNum: '',
        trackingUrl: '',
        shippedBy: '',
        shippedOn: ''
      },
      tabView: 1
    }
  },
  computed:{
    blockedUsers(){
      return this.softBlocks.filter((user)=> user.data.status == 'blocked')
    },
    appealedUsers(){
      return this.softBlocks.filter((user)=> user.data.status == 'appealed')
    },
    unblockedUsers(){
      return this.softBlocks.filter((user)=> user.data.status == 'unblocked')
    },
  },
  components:{
    SpinningIcon,
    ProcessingModal,
    // headless/ui (modal)
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    // hero icon
    HandThumbUpIcon,
    HandThumbDownIcon,
    TrashIcon,
    ArrowPathIcon,
    CalendarDaysIcon
  },
  watch:{
    'addForm.email': function (newVal, oldVal) {
      //debounce
      clearTimeout(timeout);
      this.addForm.uid = '';
      this.isGettingUid = newVal ? true : false;
      timeout = setTimeout(async () => {        
        if (newVal) {
          // get the UID
          // const uid = this.getUID(newVal);
          let user = await getUserOtherInfoFromEmail(newVal);

          if (user) {
            this.addForm.uid = user.uid;
          }
          this.isGettingUid = false;
        }
      }, 1500);
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    convertDate(time) {
     return moment.unix(time).format('ll')
    },
    async getList(){
      console.log('Getting list of soft blocked users...')
      this.softBlocks = await getSoftBlocks();
    },
    async showPostExamSurveyInfo(userId) {
      this.isPostExamSurveyViewModalOpen = true;
      this.postExamSurveyInfo = await getPostExamSurveyInfo(userId);
    },
    async showAchievementBoxInfo(userId) {
      this.isAchievementBoxViewModalOpen = true;
      this.achievementBoxInfo = await getAchievementBoxInfo(userId)
    },
    async block(){

      this.isAdding = true;
      console.log('Adding user to soft blocks...');
      let res = await addSoftBlock(this.addForm);
      if (!res.error) {
        await this.getList();
        swal.fire(
          'Success!',
          `User has been blocked`,
          'success'
          );
          this.closeAddModal();
      }else{
          swal.fire(
            'Error!',
            res.message,
            'error'
            );
      }
      this.isAdding = false;

    },
    async approveAppeal(user){

      this.isProcessing = true;
      this.processingMessage = `Please wait. Approving appeal for ${user.data.userEmail}...`
    
      if ((user.data.postExamSurveyStatus == 'approved' || (user.newPostExamSurveyStatus && user.newPostExamSurveyStatus == 'approved')) && 
        (user.data.achievementBoxStatus == 'approved' || (user.newAchievementBoxStatus && user.newAchievementBoxStatus == 'approved'))) {
        
        // approve appeal!
        // Change soft block status to 'unblocked'
        // Set caap exam data value from 'active' to 'expired'
        let res = await approveSoftBlock(user);

        if (!res.error) {
          await this.getList();
          swal.fire(
            'Success!',
            `Appeal has been approved!`,
            'success'
            );
        }else{
            swal.fire(
              'Error!',
              res.message,
              'error'
            );
        }

      }else{
        swal.fire(
          'Error!',
          'Unable to approve this appeal. Make sure both post exam survey and achievement box status were approved',
          'error'
        );
      }

      this.isProcessing = false;
      this.processingMessage = ''

    },
    async rejectAppeal(user){
      
      this.isProcessing = true;
      this.processingMessage = `Please wait. Rejecting appeal for ${user.data.userEmail}...`
      
      // check if post exam survey and achievement status are not both approved
      if(( (user.data.postExamSurveyStatus != 'submitted' && user.data.postExamSurveyStatus != 'approved') || (user.newPostExamSurveyStatus != 'undefined' && user.newPostExamSurveyStatus == 'rejected') ) ||
        ( (user.data.achievementBoxStatus != 'submitted' && user.data.achievementBoxStatus != 'approved') ||  (user.newAchievementBoxStatus != 'undefined' && user.newAchievementBoxStatus == 'rejected' ) )){
        

          let res = await rejectSoftBlock(user);

          if (!res.error) {
            await this.getList();
            swal.fire(
              'Success!',
              `Appeal has been rejected!`,
              'success'
              );
          }else{
              swal.fire(
                'Error!',
                res.message,
                'error'
              );
          }

      }else{
        swal.fire(
          'Error!',
          'Unable to reject this appeal. Please ensure that both items have been reviewed, and their statuses are not approved',
          'error'
        );   
      }

      this.isProcessing = false;
      this.processingMessage = ''


    },
    async remove(user){

      const isConfirmed = confirm("Are you sure you want to extend this user?");
      if (!isConfirmed) {
        return;
      }

      this.isProcessing = true;
      this.processingMessage = `Please wait. Removing ${user.data.userEmail} from the list...`

      let res = await removeSoftBlock(user);
      
      if (!res.error) {
          await this.getList();
          this.closeAddModal();
          swal.fire(
            'Success!',
            `User has been removed, and the expiration date has been extended by 3 months.`,
            'success'
            );
        }else{
            swal.fire(
              'Error!',
              res.message,
              'error'
            );
        }

        this.isProcessing = false;
        this.processingMessage = ''
    },
    async openShippingInfoModal(i){

      this.isUpdateShippingModalOpen = true;
      this.isRetrievingAchievementBoxInfo = true;
      this.shippingInfo.indexLoc = i;

      const res = await getAchievementBoxInfo(this.unblockedUsers[i].data.userId);
      this.shippingInfo.courierName = res.courierName;
      this.shippingInfo.trackingNum = res.trackingNum;
      this.shippingInfo.trackingUrl = res.trackingUrl;
      this.shippingInfo.shippedBy = res.metadata.shippedBy;
      this.shippingInfo.shippedOn = res.metadata.shippedOn ? this.formatDate(res.metadata.shippedOn, 'YYYY-MM-DD') : '';
      this.isRetrievingAchievementBoxInfo = false;

    },
    async updateShippingInfo(i){

      
      console.log('Updating shipping info...')
      
      // validate data here...
      if (!this.shippingInfo.courierName || !this.shippingInfo.trackingNum || !this.shippingInfo.shippedOn || !this.shippingInfo.shippedBy) {
        
        swal.fire(
          'Error!',
          'Please fill out all required fields.',
          'error'
          );
          return;
          
        }
      
       this.isUpdatingShippingInfo = true;
      
      let res = await updateShipping(this.unblockedUsers[this.shippingInfo.indexLoc], this.shippingInfo);

        if (!res.error) {
          this.closeUpdateShippingModal();
          swal.fire(
            'Success!',
            `Shipping info has been udpated!`,
            'success'
            );
        }else{
            swal.fire(
              'Error!',
              res.message,
              'error'
            );
        }

      this.isUpdatingShippingInfo = false;

    },

    formatDate(timestamp, format = ''){
      if (timestamp) {
        const dateObject = new Date(parseInt(timestamp));
        console.log(format)
        return moment(dateObject).format(format ? format : "MMM. D, YYYY hh:mm:ss A");
      }else{
        return '-'
      }
    },

    closeAddModal(){
      this.addForm.email = '';
      this.isAddModalOpen =  false;
    },
    closePostExamSurveyViewModal(){
      this.isPostExamSurveyViewModalOpen = false;
      this.postExamSurveyInfo = null;
    },
    closeAchievementBoxViewModal(){
      this.isAchievementBoxViewModalOpen = false;
      this.achievementBoxInfo = null;
    },
    closeUpdateShippingModal(){
      this.isUpdateShippingModalOpen = false;
      this.shippingInfo.indexLoc = null;
      this.shippingInfo.courierName = '';
      this.shippingInfo.trackingNum = '';
      this.shippingInfo.trackingUrl = '';
      this.shippingInfo.shippedBy = '';
      this.shippingInfo.shippedOn = '';
    },
  },
}
</script>

<style lang="">
  
</style>